import { gql } from '@apollo/client';
// local imports
import { API_SUPV_OPPORTUNITY } from '../config/api';

export const OPPORTUNITY_MATCHES_QUERY = gql`
  query OpportunityMatches(
    $opportunity_id: Int!
    $input: OpportunityMatchesInput!
    $pathBuilder: String!
  ) {
    opportunityMatches(
      opportunity_id: $opportunity_id,
      input: $input
    )
    @rest(
      type: "OpportunityMatchesPayload",
      path: "${API_SUPV_OPPORTUNITY}matches/",
      pathBuilder: $pathBuilder
    ) {
      count
      results {
        id
        owner_status
        owner_status_updated_at
        employee_status
        employee_status_updated_at
        match_rate
        growth_rate
        is_new
        employee {
          id
          code
          first_name
          last_name
          email
          is_profile_accessible
          current_job {
            id
            title
            code
            location {
              id
              title
              city
              state
              country
            }
          }
          location {
            id
            title
            city
            state
            country
          }
          work_location {
            id
            title
            city
            state
            country
          }
          manager {
            id
            code
            first_name
            last_name
            email
          }
        }
        employee_skills {
          id
          title
          abbr
          current_level
          inferred_level
          is_inference_newer
        }
        booking {
          id
          employee_id
          status
          employee_requested_at
          manager_requested_at
          confirmed_at
        }
      }
    }
  }
`;
