import { gql } from '@apollo/client';
// local imports
import { API_TALENT_FINDER_EMPLOYEE } from '../config/api';

export const EMPLOYEE_DETAILS_QUERY = gql`
  query EmployeeDetails(
    $employee_id: Int!
  ) {
    employeeDetails(
      employee_id: $employee_id
    )
    @rest(
      type: "TalentEmployee",
      path: "${API_TALENT_FINDER_EMPLOYEE}"
    ) {
      id
      code
      first_name
      last_name
      email
      performance_rating
      years_on_job
      is_profile_accessible
      current_job {
        id
        title
        code
        location {
          id
          city
          state
          country
        }
      }
      work_location {
        id
        city
        state
        country
      }
      location {
        id
        city
        state
        country
      }
      manager {
        id
        code
        first_name
        last_name
        email
      }
    }
  }
`;
