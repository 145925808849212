import { gql } from '@apollo/client';
// local imports
import { API_HR_REDEPLOYMENT_EMPLOYEES } from '../config/api';

export const REDEPLOYMENT_EMPLOYEES_QUERY = gql`
  query RedeploymentEmployees(
    $plan_id: Int!
    $input: RedeploymentEmployeesInput!
    $pathBuilder: String!
  ) {
    redeploymentEmployees(
      plan_id: $plan_id,
      input: $input
    )
    @rest(
      type: "RedeploymentEmployeesPayload",
      path: "${API_HR_REDEPLOYMENT_EMPLOYEES}",
      pathBuilder: $pathBuilder
    ) {
      count
      results {
        id
        code
        first_name
        last_name
        email
        performance_rating
        years_on_job
        is_profile_accessible
        job {
          # id
          title
          code
          match_rate
          location {
            id
            city
            state
            country
          }
        }
        location {
          id
          city
          state
          country
        }
        work_location {
          id
          city
          state
          country
        }
        manager {
          id
          code
          first_name
          last_name
          email
        }
      }
    }
  }
`;
