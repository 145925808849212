import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN_EMPLOYEES } from '../config/api';

export const DEV_PLAN_EMPLOYEES_QUERY = gql`
  query DevPlanEmployees(
    $devplan_id: Int!
    $input: DevPlanEmployeesInput!
    $pathBuilder: String!
  ) {
    devplanEmployees(
      devplan_id: $devplan_id,
      input: $input
    )
    @rest(
      type: "DevPlanEmployeesPayload",
      path: "${API_DEV_PLAN_EMPLOYEES}",
      pathBuilder: $pathBuilder
    ) {
      count
      results {
        id
        code
        first_name
        last_name
        email
        performance_rating
        years_on_job
        is_profile_accessible
        is_devplan_active
        initial_match_rate
        current_match_rate
        current_job {
          id
          title
          code
          location {
            id
            city
            state
            country
          }
        }
        location {
          id
          city
          state
          country
        }
        work_location {
          id
          city
          state
          country
        }
        manager {
          id
          code
          first_name
          last_name
          email
        }
        skills {
          id
          title
          abbr
          current_level
          inferred_level
          is_inference_newer
          expected_level
          is_satisfied
        }
      }
    }
  }
`;
