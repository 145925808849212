import { memo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import floor from 'lodash/floor';
import { FormattedMessage, FormattedNumber } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// Skillmore UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import AccountCircleAlt from '@empathco/ui-components/src/icons/AccountCircleAlt';
// local imports
import { AdminEmployee, AdminJob } from '../graphql/types';
import { TalentEmployeeObject, DevPlanEmployee, TalentEmployeeDetails } from '../graphql/customTypes';
import { Job } from '../models/job';
import useModels from '../helpers/models';
import EmployeeName from '../elements/EmployeeName';
import RoleName from '../v3/RoleName';
// SCSS imports
import { label, avatar } from './EmployeeSimpleBand.module.scss';

type EmployeeSimpleBandProps = {
  employee: TalentEmployeeObject | DevPlanEmployee | TalentEmployeeDetails | AdminEmployee;
  route?: string | null;
  onJobClick?: (code: string, job?: Job | AdminJob, context?: TalentEmployeeObject | AdminEmployee) => void;
  disabled?: boolean | null;
  isStandalone?: boolean;
  withMatchIndicator?: boolean;
}

const EmployeeSimpleBandPropTypes = {
  // attributes
  employee: PropTypes.object.isRequired as
    Validator<TalentEmployeeObject | DevPlanEmployee | TalentEmployeeDetails | AdminEmployee>,
  route: PropTypes.string,
  onJobClick: PropTypes.func,
  disabled: PropTypes.bool,
  isStandalone: PropTypes.bool,
  withMatchIndicator: PropTypes.bool
};
// eslint-disable-next-line complexity
const EmployeeSimpleBand: FunctionComponent<EmployeeSimpleBandProps> = ({
  employee,
  route,
  onJobClick,
  disabled = false,
  isStandalone = false,
  withMatchIndicator = false
}) => {
  const { getEmployeeLocationStr } = useModels();
  const { performance_rating, years_on_job, current_job, location, work_location, manager } = employee || {};
  const { code, title, location: jobLocation } = current_job || {};
  return (
    <>
      <Grid
          item
          container
          xs={isStandalone ? 8 : 7}
          sm={12}
          md={4}
          lg={3}
          alignItems="center"
      >
        <AccountCircleAlt color="inherit" className={avatar}/>
        <Box pl={1}>
          <EmployeeName
              employee={employee}
              variant="h4"
              manager
              route={route}
              disabled={disabled ? true : undefined}
          />
          {onJobClick && current_job && code && title ? (
            <Box>
              <RoleName
                  code={code}
                  title={title}
                  variant="body1"
                  role={current_job}
                  context={employee}
                  onClick={onJobClick}
              />
            </Box>
          ) : (
            <BoxTypography variant="body2" color="text.label">
              {title || '—'}
            </BoxTypography>
          )}
          <BoxTypography variant="body2" color="text.label" pb={0.25}>
            {getEmployeeLocationStr(work_location, jobLocation, location) || (
              <FormattedMessage id="employees.not_available"/>
            )}
          </BoxTypography>
        </Box>
      </Grid>
      <GridBox
          item
          xs={isStandalone ? 4 : 5}
          sm={withMatchIndicator ? 3 : 4}
          md={withMatchIndicator ? 2 : 3}
          lg={withMatchIndicator ? 3 : undefined}
          container
          flexDirection="column"
          alignItems="center"
      >
        <BoxTypography variant="caption" color="text.label" pt={1.5} className={label}>
          <FormattedMessage id="hr.talentfinder.column.manager"/>
        </BoxTypography>
        <Box pt={2.25} pb={1.5}>
          {manager ? (
            <EmployeeName
                variant="subtitle2"
                employee={manager}
                manager
                disabled={disabled ? true : undefined}
            />
          ) : '—'}
        </Box>
      </GridBox>
      <GridBox
          item
          xs={withMatchIndicator ? 4 : 6}
          sm={withMatchIndicator ? 3 : 4}
          md={withMatchIndicator ? 2 : 3}
          container
          flexDirection="column"
          alignItems="center"
      >
        <BoxTypography variant="caption" color="text.label" pt={1.5} className={label}>
          <FormattedMessage id="hr.talentfinder.column.rating"/>
        </BoxTypography>
        <BoxTypography variant="h4" py={1.5}>
          {isNil(performance_rating) ? '—' : <FormattedNumber value={round(performance_rating)}/>}
        </BoxTypography>
      </GridBox>
      <GridBox
          item
          xs={withMatchIndicator ? 4 : 6}
          sm={withMatchIndicator ? 3 : 4}
          md={2}
          lg={withMatchIndicator ? undefined : 3}
          container
          flexDirection="column"
          alignItems="center"
      >
        <BoxTypography variant="caption" color="text.label" pt={1.5} className={label}>
          <FormattedMessage id="hr.talentfinder.column.years"/>
        </BoxTypography>
        <BoxTypography variant="h4" py={1.5}>
          {isNil(years_on_job) ? '—' : <FormattedNumber value={floor(years_on_job)}/>}
        </BoxTypography>
      </GridBox>
    </>
  );
};

EmployeeSimpleBand.propTypes = EmployeeSimpleBandPropTypes;

export default memo(EmployeeSimpleBand);
