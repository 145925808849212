import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_ADVISEES } from '../config/api';

export const EMPLOYEE_ADVISEES_QUERY = gql`
  query EmployeeAdvisees(
    $selected_employee_id: String
    $limit: Int
    $offset: Int
  ) {
    employeeAdvisees(
      selected_employee_id: $selected_employee_id,
      limit: $limit,
      offset: $offset
    )
    @rest(
      type: "SkillAdvisorsPayload",
      path: "${API_EMPLOYEE_ADVISEES}"
    ) {
      count
      results {
        id
        code
        first_name
        last_name
        email
        is_profile_accessible
        current_job {
          id
          title
          code
          location {
            id
            title
            city
            state
            country
          }
        }
        location {
          id
          title
          city
          state
          country
        }
        work_location {
          id
          title
          city
          state
          country
        }
        skills {
          id
          title
          abbr
          current_level
          inferred_level
          is_inference_newer
          is_new
          status
          status_updated_at
        }
      }
    }
  }
`;
