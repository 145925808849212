import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_ADVISORS } from '../config/api';

export const EMPLOYEE_ADVISORS_QUERY = gql`
  query EmployeeAdvisors(
    $selected_employee_id: String
    $limit: Int
    $offset: Int
  ) {
    employeeAdvisors(
      selected_employee_id: $selected_employee_id,
      limit: $limit,
      offset: $offset
    )
    @rest(
      type: "SkillAdvisorsPayload",
      path: "${API_EMPLOYEE_ADVISORS}?{args}"
    ) {
      count
      results {
        id
        first_name
        last_name
        code
        email
        current_job {
          title
          management_level
          location {
            country
            city
            state
          }
        }
        location {
          country
          city
          state
        }
        work_location {
          country
          city
          state
        }
        skills {
          id
          title
          abbr
          current_level
          # inferred_level - not provided by backend
          # is_inference_newer - not provided by backend
          # is_new - not used in UI
          status
          status_updated_at
        }
      }
    }
  }
`;
