import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_EMPLOYEES } from '../config/api';

export const ADMIN_EMPLOYEES_QUERY = gql`
  query AdminEmployees(
    $search: String
    $location_id: Int
    $state_id: Int
    $country_id: Int
    $org_id: Int
    $manager_id: String
    $management_level: Int
    $management_level_min: Int
    $management_level_max: Int
    $by_sso: Boolean
    $sort_by: AdminEmployeesSort
    $direction: SortDirection
    $limit: Int
    $offset: Int
  ) {
    adminEmployees(
      search: $search,
      location_id: $location_id,
      state_id: $state_id,
      country_id: $country_id,
      org_id: $org_id,
      manager_id: $manager_id,
      management_level: $management_level,
      management_level_min: $management_level_min,
      management_level_max: $management_level_max,
      by_sso: $by_sso,
      sort_by: $sort_by,
      direction: $direction,
      limit: $limit,
      offset: $offset
    )
    @rest(
      type: "AdminEmployeesPayload",
      path: "${API_ADMIN_EMPLOYEES}?{args}"
    ) {
      count
      sso_users_count
      results {
        id
        code
        first_name
        last_name
        email
        is_countable
        current_job {
          id
          code
          title
          location {
            id
            title
            city
            state
            country
            # country_id
            # state_id
          }
        }
        manager {
          id
          code
          first_name
          last_name
          email
        }
        profile
        location {
          id
          title
          city
          state
          country
          # country_id
          # state_id
        }
        work_location {
          id
          title
          city
          state
          country
          # country_id
          # state_id
        }
        by_sso
        management_level
        is_contractor
        org_unit {
          id
          title
        }
        org {
          id
          title
        }
        years_on_job
        performance_rating
        is_esau_untouchable
        email
        is_inference_target
        skill_count
      }
    }
  }
`;
